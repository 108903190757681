<div class="content" *ngIf='!isLoggedIn'>
  <mat-card>

    <mat-card-title>Welcome to the myAgathon platform</mat-card-title>
    <mat-card-subtitle>Please <span id="login-link" (click)="process()">log in or register</span> an account.</mat-card-subtitle>
    <p>myAgathon is your one-stop access to all your Agathon related digital assets.</p>   

    <mat-accordion>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>How do I register?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>You can register by choosing the "sign up now" option in the sign in panel. Please use your business email with a domain associated with your machine.</p>
        <img src="assets/signup.png">
      </mat-expansion-panel>

      <mat-expansion-panel>
          <mat-expansion-panel-header>
          <mat-panel-title>Where do I find the machine number?</mat-panel-title>
          </mat-expansion-panel-header>
          <p>The machine number is the serial number of your machine. You will find it on the plaque of any machine associated with your company or division.</p>
          <img src="assets/type_plaque.png">
      </mat-expansion-panel>

      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>I am unable to sign up. What should I do?</mat-panel-title>
          </mat-expansion-panel-header>
          <p>If you are experiencing issues during the registrations process, please do not hesitate to contact us</p>
          <mat-card-actions>
              <a mat-stroked-button href="https://www.agathon.ch/#contact" target="_blank">Contact</a>
          </mat-card-actions>
      </mat-expansion-panel>

    </mat-accordion>
    <br/>
    <mat-card-footer>© 2020 Agathon AG, Bellach, Switzerland.</mat-card-footer>
</mat-card>
</div>

<app-dashboard *ngIf='isLoggedIn' [isLoggedIn]='isLoggedIn'></app-dashboard>