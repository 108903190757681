import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  
  //private darkMode = new Subject<boolean>();

  private darkMode: BehaviorSubject<boolean>;

  constructor(private authService: MsalService) { 
    this.darkMode = new BehaviorSubject<boolean>(false);
  }

  isLoggedIn(): boolean {
    return !!this.authService.getAccount();
  }

  getMachineTypes(): string {
    return this.authService.getAccount().idTokenClaims.machineTypes;
  }

  getUserName(): string {
    return this.authService.getAccount().idTokenClaims.name;
  }

  getMode(): Observable<boolean> {
    return this.darkMode;
  }

  changeMode(darkMode: boolean) {
    this.darkMode.next(darkMode);
  }
}
