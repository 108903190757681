<mat-progress-bar color="accent" *ngIf="download$ | async as download"
  [mode]="download.state == 'PENDING' ? 'query' : 'determinate'" 
  [value]="download.progress">
</mat-progress-bar>

<main>
  <mat-card *ngIf='hasDomplus' class='card-section'>
    <mat-card-title>Dom Plus</mat-card-title>
    <img mat-card-image src="assets/domplus.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download(domplus)'>Download</button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf='hasLeoperi' class='card-section'>
    <mat-card-title>Leo Peri</mat-card-title>
    <img mat-card-image src="assets/leoperi.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download(leoperi)'>Download</button>
    </mat-card-actions>
  </mat-card>
  
  <mat-card *ngIf='hasEvopenta' class='card-section'>
    <mat-card-title>Evo Penta</mat-card-title>
    <img mat-card-image src="assets/evopenta.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download(evopenta)'>Download</button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf='has400plus' class='card-section'>
    <mat-card-title>400 Plus</mat-card-title>
    <img mat-card-image src="assets/evopenta.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download($400plus)'>Download</button>
    </mat-card-actions>
  </mat-card>
  
  <mat-card *ngIf='hasEvocombi' class='card-section'>
    <mat-card-title>Evo Combi</mat-card-title>
    <img mat-card-image src="assets/evopenta.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download(evocombi)'>Download</button>
    </mat-card-actions>
  </mat-card>
  
  <mat-card *ngIf='hasEvoquinto' class='card-section'>
    <mat-card-title>Evo Quinto</mat-card-title>
    <img mat-card-image src="assets/evopenta.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download(evoquinto)'>Download</button>
    </mat-card-actions>
  </mat-card>
  
  <mat-card *ngIf='hasNeo' class='card-section'>
    <mat-card-title>Neo</mat-card-title>
    <img mat-card-image src="assets/evopenta.png">
    <mat-card-actions>
      <button mat-raised-button (click)='download(neo)'>Download</button>
    </mat-card-actions>
  </mat-card>  
</main>
