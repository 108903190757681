<div class="content mat-app-background">
  <mat-toolbar appStickyHeader color="primary">

    <a routerLink="/" title="home">
      <div [ngSwitch]="className">
        <img src="assets/logo_negative.svg" *ngSwitchCase="'darkMode'">
        <img src="assets/logo.svg" *ngSwitchDefault>
      </div>
    </a>
    
    <div class="toolbar-spacer"></div>
  
    <div class="flex-stretch"></div>
    <mat-slide-toggle [formControl]="toggleControl" class="mr-8">
    </mat-slide-toggle>
    <mat-icon>bedtime</mat-icon>
  
    <app-menu [isLoggedIn]="loggedIn" (clicked)="handleChildEvent($event)"></app-menu>
    
    <button icon="signin" mat-raised-button color="accent" *ngIf="!loggedIn" (click)="login()">Sign in</button>
  </mat-toolbar>
  
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
