import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  @Input() isLoggedIn;
  @Output() clicked = new EventEmitter<boolean>();

  constructor() { }

  process(type: boolean) {
    this.clicked.emit(type);
  }
}
