import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Application } from '../application';
import { ApplicationService } from '../application.service';
import { AccessService } from '../access.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input() isLoggedIn;
  userName: string;
  darkMode: boolean;

  constructor(private applicationService: ApplicationService, private accessService: AccessService) { }

  ngOnInit(): void {
    this.userName = this.accessService.getUserName();
    this.accessService.getMode().subscribe(n => this.darkMode = n);
    this.getApplications();
  }

  // list of apps to display
  apps: Application[];

  /**
   * This method gets the applications from the ApplicationService and
   * set the instance variable "apps" to the value of the list returned by
   * the service.
   */
  getApplications(): void {
    this.applicationService.getApps()
      .subscribe(apps => this.apps = apps);
  }
}
