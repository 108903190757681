import { Configuration } from 'msal';
import { MsalAngularConfiguration } from '@azure/msal-angular';

// this checks if the app is running on IE
//export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/** =================== REGIONS ====================
 * 1) B2C policies and user flows
 * 2) Web API configuration parameters
 * 3) Authentication configuration parameters
 * 4) MSAL-Angular specific configuration parameters
 * =================================================
 */

// #region 1) B2C policies and user flows
export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1a_customsignuporsignin",
        resetPassword: "b2c_1a_passwordreset",
        editProfile: "b2c_1a_profileeditpasswordchange"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://agathauth.b2clogin.com/agathauth.onmicrosoft.com/B2C_1A_CustomSignUpOrSignIn",
        },
        resetPassword: {
            authority: "https://agathauth.b2clogin.com/agathauth.onmicrosoft.com/B2C_1A_PasswordReset",
        },
        editProfile: {
            authority: "https://agathauth.b2clogin.com/agathauth.onmicrosoft.com/B2C_1A_ProfileEditPasswordChange"
        }
    }
};
// #endregion


// #region 2) Web API Configuration
/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */

export const apiConfig400Plus: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/400plus'
};

export const apiConfigLeoPeri: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/leoperi'
};

export const apiConfigDomPlus: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/domplus'
};

export const apiConfigEvoPenta: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/evopenta'
};

export const apiConfigEvoQuinto: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/evoquinto'
};

export const apiConfigNeo: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/Neo'
};

export const apiConfigEvoCombi: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: ['https://agathauth.onmicrosoft.com/api/machines.read'],
    webApi: 'https://dam-docs.azurewebsites.net/evocombi'
};
// #endregion



// #region 3) Authentication Configuration
/**
 * Config object to be passed to Msal on creation. For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: '4dc183b6-d41f-479c-a001-323c221d465a',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: 'https://my.agathon.ch',
        //redirectUri: 'http://localhost:6420',
        postLogoutRedirectUri: 'https://my.agathon.ch',
        //postLogoutRedirectUri: 'http://localhost:6420',
        navigateToLoginRequestUrl: true,
        validateAuthority: false,
      },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false, //isIE, // Set this to 'true' to save cache in cookies to address trusted zones limitations in IE
    },
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest: {scopes: string[]} = {
    scopes: ['openid', 'profile'],
};

// Scopes you enter will be used for the access token request for your web API
export const tokenRequest: {scopes: string[]} = {
    scopes: apiConfig400Plus.b2cScopes // i.e. [https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read]
};
// #endregion



// #region 4) MSAL-Angular Configuration
// here you can define the coordinates and required permissions for your protected resources
export const protectedResourceMap: [string, string[]][] = [
    [apiConfigDomPlus.webApi, apiConfigDomPlus.b2cScopes],
    [apiConfigEvoCombi.webApi, apiConfigEvoCombi.b2cScopes],
    [apiConfigEvoPenta.webApi, apiConfigEvoPenta.b2cScopes],
    [apiConfigEvoQuinto.webApi, apiConfigEvoQuinto.b2cScopes],
    [apiConfigLeoPeri.webApi, apiConfigLeoPeri.b2cScopes],
    [apiConfigNeo.webApi, apiConfigNeo.b2cScopes],
    [apiConfig400Plus.webApi, apiConfig400Plus.b2cScopes]
    // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
];

/**
 * MSAL-Angular specific authentication parameters. For a full list of available options,
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular#config-options-for-msal-initialization
 */
export const msalAngularConfig: MsalAngularConfiguration = {
    popUp: false, //!isIE,
    consentScopes: [
        ...loginRequest.scopes,
        ...tokenRequest.scopes,
    ],
    //unprotectedResources: [], // API calls to these coordinates will NOT activate MSALGuard
    protectedResourceMap,     // API calls to these coordinates will activate MSALGuard
    extraQueryParameters: {}
};
// #endregion
