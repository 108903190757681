import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Saver, SAVER } from './saver.provider';
import { download, Download } from './download';
import { AuthError, InteractionRequiredAuthError } from 'msal';
import { MsalService } from '@azure/msal-angular';
import { error } from 'protractor';


@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private http: HttpClient, @Inject (SAVER) private save: Saver, private authService: MsalService) { }

  download(url: string, filename?: string): Observable<Download> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(download(blob => this.save(blob, filename)))
  }


  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

  getIndex(url: string) {
    this.http.get(url, {responseType: 'text'}).subscribe({
      next: (response) => {

      },
      error: (err: AuthError) => {
        // If there is an interaction required error,
        // call one of the interactive methods and then make the request again.
        if (InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)) {
          this.authService.acquireTokenPopup({
            scopes: this.authService.getScopesForEndpoint(url)
          }).then(() => {
            this.http.get(url).toPromise()
              .then(response => {

              });
          });
        }
      }
    });
  }
}
