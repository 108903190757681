import { Component, OnInit,  Output, EventEmitter } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccessService } from '../access.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Output() clicked = new EventEmitter<boolean>();
  isLoggedIn: boolean;

  constructor(private accessService: AccessService, private authService: MsalService) { }

  ngOnInit() {
    this.isLoggedIn = this.accessService.isLoggedIn();
  }

  process() {
    this.authService.loginRedirect();
  }
}
