import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { 
  apiConfigLeoPeri, 
  apiConfigEvoCombi, 
  apiConfigDomPlus, 
  apiConfigEvoPenta, 
  apiConfigEvoQuinto, 
  apiConfigNeo, 
  apiConfig400Plus,
  b2cPolicies} from '../app-config';
import { FileService } from '../file.service';
import { AccessService } from '../access.service';
import { Download } from '../download';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-documentations',
  templateUrl: './documentations.component.html',
  styleUrls: ['./documentations.component.scss']
})
export class DocumentationsComponent implements OnInit {
  subscriptions: Subscription[] = [];

  domplus = { name: 'TDOK-DomPlus-BA-05-DE.pdf', url: apiConfigDomPlus.webApi }
  leoperi = { name: 'TDOK-LeoPeri-BA-12-en.pdf', url: apiConfigLeoPeri.webApi }
  $400plus = { name: 'TDOK-TRANSP-LeoPeri-06-de.pdf', url: apiConfig400Plus.webApi }
  evocombi = { name: 'TDOK-TRANSP-LeoPeri-06-de.pdf', url: apiConfigEvoCombi.webApi }
  evopenta = { name: 'TDOK-TRANSP-LeoPeri-06-de.pdf', url: apiConfigEvoPenta.webApi }
  evoquinto = { name: 'TDOK-TRANSP-LeoPeri-06-de.pdf', url: apiConfigEvoQuinto.webApi }
  neo = { name: 'TDOK-TRANSP-LeoPeri-06-de.pdf', url: apiConfigNeo.webApi }
  
  download$: Observable<Download>

  has400plus: boolean;
  hasLeoperi: boolean;
  hasDomplus: boolean;
  hasEvocombi: boolean;
  hasEvopenta: boolean;
  hasEvoquinto: boolean;
  hasNeo: boolean;

  constructor(private broadcastService: BroadcastService,
    private fileService: FileService, 
    private accessService: AccessService,
    private authService: MsalService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {

    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    loginSuccessSubscription = this.broadcastService.subscribe('msal:acquireTokenSuccess', (payload) => {
      console.log('access token acquired at: ' + new Date().toString());
      console.log(payload);
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:acquireTokenFailure', (payload) => {
      console.log('access token acquisition fails');
      console.log(payload);
      this.authService.loginRedirect(b2cPolicies.authorities.signUpSignIn);
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    for(let i = 0 ; i < this.accessService.getMachineTypes().length ; i++){
      if (this.accessService.getMachineTypes()[i] === "neo") {
        this.hasNeo = true;
      } else if (this.accessService.getMachineTypes()[i] === "400plus") {
        this.has400plus = true;
      } else if (this.accessService.getMachineTypes()[i] === "leoperi") {
        this.hasLeoperi = true;
      } else if (this.accessService.getMachineTypes()[i] === "domplus") {
        this.hasDomplus = true;
      } else if (this.accessService.getMachineTypes()[i] === "evocombi") {
        this.hasEvocombi = true;
      } else if (this.accessService.getMachineTypes()[i] === "evopenta") {
        this.hasEvopenta = true;
      } else if (this.accessService.getMachineTypes()[i] === "evoquinto") {
        this.hasEvoquinto = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  download({name, url}: {name: string, url: string}) {
    this.download$ = this.fileService.download(url, name);
  }
}
