import { Injectable } from '@angular/core';
import { Application } from './application';
import { APPS } from './mock-applications';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor() { }

  /**
   * This is the function that returns all Applications.
   * As of right now, it gets them from a static file called mock-applications.ts.
   * This could later also be an http call to a REST API.
   * This API could only return the Apps, the user has access to.
   * Right now, every user will see every App.
   */
  getApps(): Observable<Application[]> {
    return of(APPS);
  }
}
