<button *ngIf="isLoggedIn" mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu" color="accent">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item (click)="process(true)">
      <mat-icon color="accent">settings</mat-icon>
      <span>Change password</span>
    </button>
    <button mat-menu-item disabled>
        <mat-icon>translate</mat-icon>
        <span>Change language</span>
      </button>
    <button mat-menu-item (click)="process(false)">
      <mat-icon color="warn">logout</mat-icon>
      <span color="warn">Logout</span>
    </button>
  </mat-menu>
