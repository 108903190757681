<a routerLink="{{ app.link }}">
    <mat-card *ngIf="darkMode">
        <img mat-card-image  src="{{ app.dark_thumbnail }}" title="{{ app.name | titlecase }}">
        <mat-card-content>{{ app.name }}</mat-card-content>
    </mat-card>

    <mat-card *ngIf="!darkMode">
        <img mat-card-image src="{{ app.thumbnail }}" title="{{ app.name | titlecase }}">
        <mat-card-content>{{ app.name }}</mat-card-content>
    </mat-card>
</a>
