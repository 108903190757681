import { Component, OnInit, Input } from '@angular/core';
import { Application } from '../application';

@Component({
  selector: 'app-application-button',
  templateUrl: './application-button.component.html',
  styleUrls: ['./application-button.component.scss']
})
export class ApplicationButtonComponent implements OnInit {

  @Input() app: Application;
  @Input() darkMode: boolean;

  constructor() { }

  ngOnInit(): void { }
}
