import { Application } from "./application";

/**
 * This static list contains examples of applications to display as buttons.
 * It is now used by the ApplicationService.
 * Later it could be replaced by an API call in the service.
 * 
 * Edit: names have to be LOWERCASE!
 * Otherwise, the the search function might have case sensitivity issues.
 * If all names are lowercase, the search function is case insensitive
 */
export const APPS:Application[] = [
    {
      id:1,
      name:"Documentation",
      link:"documentations",
      thumbnail:"assets/documentation.svg",
      dark_thumbnail: "assets/documentation_red.svg"
    },
    {
      id:2,
      name:"Tutorials",
      link:"tutorials",
      thumbnail:"assets/tutorial.svg",
      dark_thumbnail: "assets/tutorial_red.svg"
    },
    {
      id:3,
      name:"Support",
      link:"support",
      thumbnail:"assets/support.svg",
      dark_thumbnail: "assets/support_red.svg"
    },
    {
      id:4,
      name:"Forum",
      link:"forum",
      thumbnail:"assets/default-logo.svg",
      dark_thumbnail: "assets/default-logo.svg"
    },
    {
      id:5,
      name:"Shop",
      link:"shop",
      thumbnail:"assets/default-logo.svg",
      dark_thumbnail: "assets/default-logo.svg"
    }
  ];
